.aside-dark .menu .menu-item .menu-link .menu-title{
    margin-left: 10px;
    font-weight: 700;
    color: #333 !important;
}
.aside-dark .menu .menu-item .menu-link.active{
    background-color: hsl(181, 40%, 82%) !important;
    border-radius: 4px;
}

.aside-dark .menu .menu-item .menu-link:hover {
    background-color:rgb(191, 227, 227) !important;
}


.bi {
    color: #47a5a6;
    font-size: 30px;
}

.aside-menu .menu-item {
    margin-left: 5px !important;
    font-size: 15px !important;
}

.aside-menu .menu .menu-item .menu-link, .aside-menu .menu .menu-item .menu-content {
    padding: 15px;
}