
.card-notifications {
    border: 1px solid #dfdfdf !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
    font-size: 16px;
  }
  
  .closeButton:hover {
    cursor: pointer;
  }
  