.selectDate {
    overflow: hidden;
}

.selectDate input:focus-visible {
    outline: none;
}

.selectDate input {
    font-weight: bold;
    padding-bottom: 5px;
    border: none;
    border-bottom: 1px solid #9e9e9e;
}

.react-datepicker__day-names, .react-datepicker__week {
    font-size: 1rem;
}

.react-datepicker__week {
    font-weight: normal;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    margin: 0.36rem;
}