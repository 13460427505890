.searchPersonWrapper {
  background-color: #cccccc21 !important;
  border-radius: 4px !important;
}
.searchPerson {
  background: transparent !important;
  border: none !important;
  color: #fff !important;
  font-weight: normal !important;
  padding: 0 !important;
  padding-left: 20px !important;
}

.searchPerson::placeholder {
  color: #ccc !important;
}
.input-group-append .input-group-text {
  background: transparent !important;
  border: none !important;
}
.input-group-append .input-group-text .bi {
  color: #999 !important;
}

.searchResults {
  z-index: 9999;
  position: fixed;
  background: #fff;
  width: 250px;
  max-height: 68vh;
  border: 1px solid #ccc;
  overflow: scroll;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12),0 3px 1px -2px rgba(0,0,0,.2) !important
}

.searchResults li {
  list-style: none;
  font-size: 17px;
}
.searchResults li a {
  color: #26a69a !important;
  line-height: 20px;
}
.searchResults li:hover a {
  color: #33383b !important
}

.searchResults li:hover {
  cursor: pointer;
  background-color: #eee;
}

.container-fluid {
  padding: 0 9px !important;
}