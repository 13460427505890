thead, tbody {
  border-bottom: 1px solid #000;
}

.btn-light-primary,
.btn-light-primary:focus {
  color: #47a5a6 !important;
  border: 1px solid #d5f4f4 !important;
  background-color: #47a5a629 !important;
  text-transform: uppercase !important;
  font-weight: bold;
}
.btn-light-primary:hover {
  background-color: #47a5a6 !important;
  color: #fff !important;
  border: 1px solid #47a5a6 !important;
}
.btn-light-primary:hover .bi{
  color: #fff !important;
}

.btn-light-primary .bi {
  color: #47a5a6 !important;
  display: inline !important;
}




.exportCSV,
.exportCSV:focus {
  color: #ff9800 !important;
  border: 1px solid #e7ddd329 !important;
  background-color: #a6744729 !important;
  text-transform: uppercase !important;
  font-weight: bold;
}


.exportCSV:hover {
  background-color: #ff9800 !important;
  color: #fff !important;
  border: 1px solid #ff9800 !important;
}
.exportCSV:hover .bi{
  color: #fff !important;
}

.exportCSV .bi {
  color: #ff9800 !important;
  display: inline !important;
}


.viewDetails .bi, .signOut .bi {
  color: #fff !important;
}
.viewDetails, .signOut {
  /* border-radius: 20px; */
  background-color: #47a5a6 !important;
}

.viewDetails:hover, .signOut:hover {
  background-color: #2bbbad !important;
}