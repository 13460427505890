@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

.header-fixed {
  background-color: #f5f5f5 !important;
  font-family: "Roboto", Helvetica, "sans-serif" !important;
}

.btn-primary {
  background-color: #47a5a6 !important;
  color: #fff !important;
}

.scrolltop {
    background-color: #47a5a6 !important;
}