.footer {
    background-color: #33383b !important;
}

.footer .justify-content-between {
    justify-content: center !important;
}

.text-light span a {
    color: #fff;
}

#sessExpirP {
    margin: 0px !important;
    padding: 16px 0px 16px 0px !important;
    opacity: 1;
    display: block;
    border: medium none;
    background-color: rgb(255, 102, 102);
    color: rgb(255, 255, 255) !important;
    font-family: "Lato", Arial;
    font-size: 18px;
    line-height: 18px;
    position: sticky;
    text-align: center;
    bottom: 0px;
    left: 0px;
    transition: all 1s ease 0s;
    z-index: 9999;
}

#sessExpirDiv {
    position: absolute;
    width: 100%;
    bottom: 0px;
}