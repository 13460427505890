/* .symbol {
    border-radius: 3px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
} */
.usernameTopbar {
  color: #fff;
  line-height: 15px;
  text-transform: capitalize;
}

.usernameTopbar small{
    font-weight: bold;
}

.usernameTopbar h5 {
  color: #fff;
}

.symbol .bi {
  color: #fff;
}

.symbol > img {
  border-radius: 20px !important;
}

.symbol {
  align-items: center;
  justify-content: center;
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.helpLink, .appEnv {
  border-right: 1px solid #778288;
  padding-right: 20px;
  margin: 16px -1px;
}

.appEnv h1 {
  color: red !important;
  text-transform: uppercase;
  font-size: 2.5rem;
}

.aside-toggle {
  box-shadow: none !important;
}

/* .symbol:active,
.symbol:focus,
.symbol:focus-visible,
.symbol:focus-within,
.symbol:visited,
.symbol:hover {
  background-color: #fff;
  color: #000 !important;
}

.symbol:hover .usernameTopbar,
.symbol:hover .usernameTopbar h5,
.symbol:hover .symbol .bi,
.symbol:active .usernameTopbar,
.symbol:active .symbol .bi,
.symbol:active .usernameTopbar h5 {
  color: #000 !important;
} */
