.card-dashboard {
  border: 1px solid #47a5a6 !important;
}

.card-dashboard .text-muted {
  color: #8e8e8e !important;
}

.overdueSiteVisitors h2,
.upcomingScheduledVisits h2,
.staffOnSite h2,
.visitorsOnSite h2 {
  font-size: 2rem;
  font-weight: 900 !important;
}

.staffOnSite h1,
.visitorsOnSite h1,
.upcomingScheduledVisits h1,
.unverifiedPeople h1 {
  color: #009688 !important;
  font-size: 5rem;
  font-weight: 900;
}

.visitorsOnSite:hover, .staffOnSite:hover {
  cursor: pointer;
}

.presentResident h1 {
  font-size: 4rem;
  font-weight: normal;
}

.residentNotSeen h1,
.overdueSiteVisitors h1 {
  color: red !important;
  font-size: 5rem;
  font-weight: 900;
}

.btn-ll {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
  text-transform: uppercase;
}

.btn-ll:hover {
  background-color: #2bbbad !important;
}

.table tbody tr:last-child td.noRecords {
  border-bottom: 2px solid #000 !important;
}

th,
td {
  font-size: 15px !important;
}

.toolbar {
  background: transparent !important;
}

/* MultiSelect Dropdown CSS Customization */

.filterDashboard .rmsc .dropdown-container {
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  font-size: 14px;
}

.filterDashboard .rmsc .dropdown-heading {
  padding-left: 0px;
}

.filterDashboard .rmsc .dropdown-container:focus-within {
  box-shadow: none;
  border-color: none;
}