.table th {
    font-weight: bold !important;
    text-align: center
}
.table td {
    text-align: center;
}

tbody {
    border-top: 1px solid gray !important;
}

a.nav-link {
    /* background: #47a5a682;
    margin: 10px 0px; */
    padding: 10px 20px !important; 
    text-transform: uppercase;
}
a.nav-link:hover {
    cursor: pointer;
    color: teal !important;
    border: 0 !important;
}
a.nav-link.active {
    color: teal !important;
    border-bottom: 3px solid teal !important;
}
.addRecord {
    margin: 13px
}

.btn-ll {
    color: #fff !important; 
    background: #47a5a6 !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
  
.btn-ll:hover {
    background-color: #2bbbad !important;
}

.cancelButton {
    float: right;
    background-color:#f5f8fa !important;
    text-transform: uppercase;
}

.actionButton {
    cursor: pointer;
    padding: calc(0.55rem + 1px) calc(0.75rem + 1px) !important;
    margin-right: 5px;
}

.btn-round {
    border-radius: 50% !important;
}

.actionButton i {
    display: flex !important;
    color: #fff !important;
    padding: 3px 1px !important;
}

.btn-orange {
    background: #ff9800 !important;
}

.btn-red {
    background: red !important;
}

.label-red i {
    color: red !important;
}

.label-green i {
    color: green !important;
}

.btn-blue {
    background: #2196f3 !important;
}

.accessBubbles {
    background:#ccc;
    padding:5px 10px;
    font-size:13px;
    margin:2px 3px;
    border-radius:10px;
    float: left;
    text-transform: capitalize !important;
}

.text-warning {
    font-size: 18px;
    font-weight: bold
}

.text-warning i {
    color: #ffc700 !important
}