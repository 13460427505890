.aside-logo{
    background-color: #33383b !important;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12),0 3px 1px -2px rgba(0,0,0,.2) !important;
    z-index: 999 !important;
}

.aside-menu {
    background-color: #f5f5f5;
}

#kt_aside_toggle .bi{
    color: #999 !important;
    transform: rotate(-360deg);
    transition-duration: 0.2s;
}

#kt_aside_toggle.active .bi{
    transform: rotate(-180deg);
    transition-duration: 0.2s;
    color: #47a5a6 !important;
    opacity: 1;
}
