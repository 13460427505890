.content {
    padding: 0px !important
}

.container {
    padding: 0 15px !important;
}

a.text-primary, a.text-hover-primary:hover {
   color: #47a5a6 !important
}

.form-check-input:checked {
    background-color: #47a5a6 !important;
    border-color: #47a5a6 !important;
}

.form-check-input:hover, select, .select-ll {
    cursor:pointer
}